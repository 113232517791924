<template>
  <router-view class="container" />
</template>

<style>
@font-face {
  font-family: "Oscine";
  src: local("Oscine"), url(./assets/Oscine-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Oscine";
  src: local("Oscine"), url(./assets/Oscine-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

#app {
  font-family: "Oscine", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container {
  display: flex;
  background: black;
  width: 100vw;
  height: 100vh;
  background-image: url("assets/nordlys_smaller.png");
  background-size: cover;
}
</style>
