<template>
  <div class="home">
    <h4 class="date_header">1. SEPTEMBER 2021</h4>
    <h1 class="main_heading">NTNU x TERRAVERA Mobilisering</h1>
    <vue-countdown
      :time="time"
      v-slot="{ days, hours, minutes, seconds }"
      class="countdown"
    >
      <div class="time_section">
        <p class="time_name">Dager</p>
        <p class="time_value">{{ days }}</p>
      </div>
      <div class="time_section">
        <p class="time_name">Timer</p>
        <p class="time_value">{{ hours }}</p>
      </div>
      <div class="time_section">
        <p class="time_name">Minutter</p>
        <p class="time_value">{{ minutes }}</p>
      </div>
      <div class="time_section">
        <p class="time_name">Sekunder</p>
        <p class="time_value">{{ seconds }}</p>
      </div>
    </vue-countdown>
    <h3 class="red_text">
      Direktesending fra NTNUs studio<br />Link publiseres 1. sept
    </h3>
    <h4>Påmelding til eventen skjer via Linkedin</h4>
    <a
      class="signup"
      href="https://www.linkedin.com/events/terraveramobilisering6827967253057695744/"
      >Påmelding</a
    >
    <div class="footer_info">
      <a
        href="https://terravera.world/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        >Personvern</a
      >
      <a
        href="https://terravera.world"
        target="_blank"
        rel="noopener noreferrer"
        >www.terravera.world</a
      >
    </div>
  </div>
</template>

<script>
import VueCountdown from "@chenfengyuan/vue-countdown";

export default {
  name: "Home",
  components: {
    "vue-countdown": VueCountdown,
  },
  data() {
    const now = new Date();
    const event = new Date("September 1, 2021 10:00");
    return {
      time: event - now,
    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}
.date_header {
  margin-top: 30vh;
  font-size: 1.4em;
}
.main_heading {
  font-size: 3em;
  margin: 0;
  letter-spacing: 0.05em;
  line-height: 1em;
}
.countdown {
  display: flex;
}
.time_section {
  margin: 10px 20px;
  margin-bottom: 0px;
}
.time_name {
  font-size: 1em;
  margin-bottom: 0;
}
.time_value {
  font-size: 2em;
  margin-top: 0;
}
.signup {
  background: #4ebf90;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 10px;
  transition: all 0.2s;
}
.signup:hover {
  transform: scale(1.08);
  opacity: 0.9;
}
.footer_info {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.footer_info a {
  text-decoration: none;
  color: white;
  border-radius: 10px;
  transition: all 0.2s;
  font-size: 0.8em;
  margin: 1px;
}
.footer_info a:hover {
  transform: scale(1.08);
  opacity: 0.9;
}
.red_text {
  color: red;
  margin: 0;
}
</style>
